<template>
  <div class="container col-xl-10 col-xxl-8 p-0 p-md-5 my-sm-5">

    <div v-if="loading && !(form.id && !form.upload_contract)" class="p-5 d-flex justify-content-center align-items-center flex-gap-2">
      <div class="spinner-border text-primary" role="status"></div>
      <span>Please wait...</span>
    </div>

    <template v-if="form.id && !form.upload_contract">
      <div class="row align-items-center p-5 bg-white border shadow-lg rounded">
        <div class="col-lg-7">
          <h1 class="display-4 mb-3">{{ form.restaurant_name }}</h1>
          <p class="col-lg-10">
            <address>
              {{ form.restaurant_address }}
              {{ form.restaurant_postcode }},
              {{ form.restaurant_city }}
            </address>
          </p>
        </div>

        <div class="col-md-10 mx-auto col-lg-5">
          <form @submit.prevent="generateContract()" class="p-4 p-md-5 border rounded bg-light">
            <div class="form-group mb-3">
              <label>Name</label>
              <input type="text" class="form-control" placeholder="Name" v-model="formContract.name" required>
            </div>

            <div class="form-group mb-3">
              <label>Place</label>
              <input type="text" class="form-control" placeholder="Place" v-model="formContract.place" required>
            </div>

            <div class="d-flex flex-column flex-md-row mb-3">
              <div class="form-group w-100">
                <label class="d-block">Signature:</label>
                <VueSignaturePad
                  class="form-control"
                  id="signature"
                  width="100%"
                  height="160px"
                  ref="signaturePad"
                  :options="{
                    penColor: '#000',
                    onBegin: () => { $refs.signaturePad.resizeCanvas() }
                  }"
                />
                <a role="button" class="small" @click="$refs.signaturePad.undoSignature()">
                  <CIcon name="cil-action-undo" /> Undo the Signature
                </a>
              </div>
            </div>

            <button class="w-100 btn btn-lg btn-primary">Sign the Contract</button>
            <hr class="my-4">
            <small class="text-muted">By clicking Sign the Contract, you agree to the terms of use.</small>
          </form>
        </div>
      </div>

      <RestaurantContract
        ref="contract"
        :form="form"
        :formContract="formContract"
        :sign="formContract.sign"
        :saveFile="false"
        @generated="handleContractGenerationComplete"
      />

      <CElementCover :opacity="0.8" v-show="loading"/>
    </template>
  </div>
</template>

<script>
export default {
  components: {
    RestaurantContract: () => import('@/components/RestaurantContract.vue'),
  },
  data() {
    return {
      form: {},
      formContract: {},
      loading: true,
    }
  },
  computed: {
    token() { return this.$route.params.token; },
  },

  async mounted() {
    await this.getRestaurant();
    this.formContract = this.getFormContract();
  },

  methods: {
    async getRestaurant() {
      try {
        const { data } = await this.$axios.get(this.$backend.MY_RESTAURANT.SHOW_BY_TOKEN, {params: { 'token': this.token }});
        this.form = data;

        if (data.upload_contract) {
          throw new Error("The contract has already been signed!");
        }
        this.loading = false;
      } catch (error) {
        this.$toast.error(error.message, {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          showCloseButtonOnHover: true,
          onClose: () => {
            // Passing data from iframe to parent
            window.top.postMessage('redirect', '*')
          },
        });
      }
    },
    getFormContract() {
      return {
        contract_lang: this.form.lang || 'en',
        name: this.form.eigenaar1_naam,
        place: this.form.restaurant_city,
        sign: null
      }
    },

    async generateContract() {
      this.$i18n.locale = this.formContract.contract_lang;
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        this.$toast.error("Please sign the contract!", {
          position: "bottom-right",
          timeout: 3000,
        });
      } else {
        this.loading = true;
        this.formContract.sign = data;
        await this.$refs.contract.generatePdf();
      }
    },

    async handleContractGenerationComplete(contractFileName) {
      try {
        await this.$axios.put(this.$backend.MY_RESTAURANT.UPDATE_BY_TOKEN, {
          'token': this.token,
          'upload_contract': contractFileName,
        });
        this.$toast.success("The contract was successfully uploaded. Thank you!", {
          position: "bottom-right",
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          showCloseButtonOnHover: false,
          onClose: () => {
            // Passing data from iframe to parent
            window.top.postMessage('redirect', '*')
          },
        });
      } catch (error) {
        this.$toast.error(error.message, {
          position: "bottom-right",
          timeout: 3000,
        });
        this.loading = false;
      }
    },
  },
}
</script>

<style scoped>
.container .form-control {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}
</style>
